section.contact {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    padding: 30px 5vw;
  }

  .news {
    background: transparent;
  }

  .form-container {
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    section.contact {
      padding: 50px 10vw; 
    }
  }



