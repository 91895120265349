.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 12px 24px;
  background: linear-gradient(89.87deg, #156b79 0.4%, #1e7e8d 100%);

  &__actions {
    display: flex;
    align-items: center;

    &__openNav {
      cursor: pointer;
      color: #fff;
      font-size: 18px;
      padding: 14px 11px;
      margin-right: 8px;
    }

    &__welcome {
      color: #fff;
      border-left: 1px solid #fff;
      padding-left: 8px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7.5px;
    color: #fff;
    cursor: pointer;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }

    &__name {
      margin: 0 10px 0 10px;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.15000000596046448px;
    }

    &__down {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
}
