.dashboard {
  &__cards {
    max-width: 1076px;
    width: 100%;
    margin: 0 auto;
    padding: 0 116px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    gap: 0.2rem;
    border-radius: 8px;
    padding: 19px;
    border-bottom: 3px solid #c2dce0;

    &__icon {
      max-width: fit-content;
      padding: 1rem;
      border-radius: 12px;
      background-color: #c3dce0;
    }

    &__title {
      padding: 0;
      margin: 0;
      margin-top: 0.5rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
    }

    &__value {
      padding: 0;
      margin: 0;
      font-weight: 400;
      font-size: 33px;
      line-height: 38.67px;
    }
  }
}
