.login {
  background-size: cover;
  min-height: 100vh;
  padding-top: 100px;

  &__card {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 353px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    box-shadow: 0px 0px 20px 0px #00000047;
    background-color: #fff;
    border-radius: 24px;
    padding: 24px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 7px;
  }

  &__title,
  &__subtitle {
    padding: 0;
    margin: 0;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #12131a;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6a6a6a;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & label.Mui-focused {
      color: #156b79;
    }
    & .MuiFormControl-root {
      margin: 0;
      padding: 0;
    }
    & .MuiFilledInput-root {
      &.Mui-focused fieldset {
        border-color: #156b79;
      }

      &::after {
        border-bottom: 2px solid #156b79;
      }
    }
  }

  &__submit {
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #156b79 !important;
    text-transform: capitalize !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46000000834465027px;
  }

  &__footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 353px;
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;

    &__title {
      grid-column-start: 1;
      grid-column-end: 3;
      font-weight: 700 !important;
      padding: 0 !important;
    }

    & p {
      margin: 0;
      padding: 10px;
      font-size: 11px;
      font-weight: 400;
      line-height: 12.89px;
      color: #ffffff;
    }
  }
}
