/* navBar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f5f5f5; /* Cor de fundo para visualização */
  }
  
  .nav-logo-container {
    display: flex;
    align-items: center;
  }
  
  .navbar-links-container {
    display: flex;
    align-items: center;
    gap: 20px; /* Espaçamento entre os elementos */
  }
  
  .UserDiv {
    display: flex;
    align-items: center;
    gap: 10px; /* Espaçamento entre os elementos dentro do UserDiv */
  }
  
  .user-info {
    font-size: 12px;
    text-align: center;
  }
  
  .navbar-menu-container {
    display: flex;
    align-items: center;
  }
  
  .nav-link {
    padding: 10px;
    color: #333;
    text-decoration: none;
    font-weight: 500;
  }
  
  .nav-link:hover {
    color: #0073e6;
    text-decoration: underline;
  }
  
  .logo-image-nav {
    max-width: 150px;
    max-height: 100px;
    height: auto;
    padding: 2px;
    border: 2px solid #ccc;
    border-radius: 8px;
  }
  
  .logout-link {
    cursor: pointer;
    font-weight: 600;
    font-size: 1.1rem;
    margin-left: 20px;
  }
  
  .drawer-container {
    width: 250px;
    padding: 20px;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column; /* Empilha elementos em dispositivos móveis */
    }
  
    .navbar-links-container {
      justify-content: center;
      margin-top: 10px;
    }
  }
  