
.logo-image-small {
    max-width: 100px;   
    height: auto;      
    display: block;    
}

.smalltext{
    font-size: xx-small;
}

.imageupload{
    align-items: center !important;
    display: flex;
    justify-content: center;
    vertical-align: top !important;
    flex-direction: column !important;
}

.griduploadimage{
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 30px !important; 
    margin-left: 15px !important;
    margin-bottom: 15px !important;
    padding-bottom: 15px !important;
    vertical-align: top !important;
}