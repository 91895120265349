.tableContainer {
  width: 80%;
  border: collapse;
  margin-left: 10%;
}

.tableHeader {
  background-Color: #f2f2f2;
  text-Align: left;
  padding: 8px;
  width: 80%;
}

.tableRow {
  padding: 2px;
  border: 1px solid #cccccc;
  cursor: pointer !important;
  user-select: none;
  transition: background-color 0.3s ease;
}

.tableRow:hover {
  background-color: #fffedf;
}

.tableCell {
  padding: 8px;
  border-width: 0.25em;
  border-color: transparent;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 16px;
}

.grid-item {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modalf {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalf-content {
  margin: 10px;
  background-color: #fff;
  border-radius: 8px;
  width: 600px !important;
  height: auto !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: large;

}

@media (max-width: 600px) {
  .modalf-content {
    background-color: #fff;
    border-radius: 8px;
    width: 320px !important;
    height: auto  !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    overflow-y: hidden;
    overflow-x: hidden;
    font-size: small !important;
    justify-content: center !important;
  ;
  }
}


.modalf-header {
  display: flex;
  justify-content: flex-end;
  background-color: lightgray;
}

/* Close button */
.close-button {
  background: transparent;
  border: none;
  font-size: 24px !important;
  padding: 0px !important;
  cursor: pointer;
  color: #000;
}

.close-button:hover {
  color: red;
}

/* Modal body */
.modalf-body {
  margin-top: 20px;
}




/* Basic styles for the card layout */
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 5px;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  text-align: left;
}

.content {
  padding: 0px;
  height: 100vh !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px !important;
}

.controls {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing between controls as needed */
  margin-bottom: 20px;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
  flex-direction: column;
  cursor: pointer;
}

.card-content h3 {
  margin: 0 0 0 0;
  font-size: 1.2em;
}

.card-content p {
  margin: 4px;
}

.card-content {
  display: flex;
}

.card-body {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 10px !important;
}

.logo-image {
  max-width: 100px !important; 
  max-height: 70px !important; 
  margin-right: 10px; 
  border-radius: 8px; 
  border: 1px solid #ccc;
  display: block;
}

.card-text {
  color: gray; 
}

.vertical-line {
  height: 20px; /* Adjust height as needed */
  width: 2px;
  background-color: #b9b9b9;
  margin: 0 10px; /* Space around the vertical line */
}