.footer-container {
    background-color: #ffffff;
    padding: 20px 50px;
  }
  
  .footer-container .social-links {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .footer-container .social-links a {
    color: inherit;
  }
  
  .footer-container .social-links a:hover {
    color: #1890ff;
  }
  
  .footer-container .links-section {
    margin-top: 30px;
  }
  
  .footer-container .links-section h6 {
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .footer-container .links-section p {
    margin-bottom: 10px;
  }
  
  .footer-container .copyright {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px 0;
  }

  .text-container {
    display: flex;
    justify-content: space-between; /* Adjust spacing between items */
  }
  
  .text-item {
    flex: 1; /* Each item takes up equal space */
    text-align: center; /* Center the text within each item */
  }