.sidebar {
  &__company {
    padding: 16px !important;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__info {
      display: flex;
      align-items: center;
      gap: 10px;

      & img {
        width: 100px;
        height: auto;
        max-height: 60px;
        object-fit: contain;
      }

      & p {
        margin: 0;
        display: flex;
        flex-direction: column;
      }

      .select-item {
        font-size: 7px;
        font-weight: 600;
        line-height: 16.41px;
        color: #2f2f2f;
      }
      
      .select-item-disabled {
        font-size: 14px;
        font-weight: 600;
        line-height: 16.41px;
        color: #aaaaaa; /* cor cinza para itens desabilitados */
      }
      

      & span {
        &.--slug {
          font-family: Roboto;
          font-size: 10px;
          font-weight: 400;
          line-height: 11.72px;
          color: #717171;
        }

        &.--title {
          font-size: 14px;
          font-weight: 600;
          line-height: 16.41px;
          color: #2f2f2f;
          white-space: wrap;
          text-align: center;
        }
      }
    }
  }

  &__indicator {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.15000000596046448px;
    margin: 0;
    color: #23333fde;
    padding: 24px;
    padding-bottom: 0;
  }

  &__icon {
    display: flex;
    align-items: center;
    &.--active path {
      stroke: #fff !important;
    }
  }
}

.select-item {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  color: #2f2f2f;
}

.select-item-disabled {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  color: #aaaaaa; /* cor cinza para itens desabilitados */
}

