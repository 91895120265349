.navInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  &__details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__breadcrumb {
    padding: 0;
    min-height: fit-content;

    &__list {
      list-style: none;
      display: flex;
      gap: 8px;
      padding: 0;
      margin: 0;
    }

    &__item {
      & svg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
      }
    }

    &__link {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      color: #23333f;

      &:hover {
        color: #156b79;
      }

      &.--first {
        text-decoration-line: underline;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 22.23px;
    letter-spacing: 0.25px;
    color: #156b79;
  }

  &__button button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 16px;
    cursor: pointer;
    color: #fff;
    background: #156b79; /* Fundo branco */
    text-transform: capitalize;
    font-size: 14px;
    outline: 0;
    border: 1px solid #156b79; /* Borda para destacar */
    transition: all 0.3s;

    &:hover {
      background: #fff; /* Fundo invertido */
      color: #156b79; /* Texto invertido */
    }

    & svg path {
      stroke: #fff;
      width: 20px;
    }
  }
}
