.info-title{
  width: 90%;
  margin: 5%;
  padding: 0% !important;
  font-size: large;
  font-weight: bold;
}

.info{
  width: 90%;
  margin: 5%;
  padding: 0% !important;
}

.welcome{
  width: 60%;
  margin: 5%;
  padding: 0% !important;
}